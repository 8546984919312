import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@/store/slices/auth';
import defaultUserImage from '@/assets/images/default-user.png';
import chevronDownIcon from '@/assets/icons/chevron-down.svg';
import { authService } from '@/services/auth.service';
import toast from 'react-hot-toast';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

import profileIcon from '@/assets/icons/profile.svg';
import logoutIcon from '@/assets/icons/logout-red.svg';
import crownIcon from '@/assets/icons/crown-dark.svg';
import terminologyIcon from '@/assets/icons/terminology.svg';

interface ProfileDropdownProps {}

const ProfileDropdown: React.FC<ProfileDropdownProps> = () => {
  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUser);
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await authService.logout();
      navigate('/login');
      toast.success('Logged out successfully');
    } catch (error) {
      toast.error('Logout failed. Please try again');
    }
  };

  const handleNavigate = (path: string) => {
    setIsOpen(false);
    navigate(path);
  };

  return (
    <>
      {/* Backdrop overlay when popover is open */}
      {isOpen && <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40" onClick={() => setIsOpen(false)} />}

      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger className="flex items-center gap-3 relative z-50 bg-white rounded-[16px] p-2 px-[0.6rem]">
          <div className="cursor-pointer flex items-center gap-3">
            <img
              width={38}
              height={38}
              className="rounded-full"
              src={currentUser?.photoURL || defaultUserImage}
              alt="User Avatar"
            />
            <p className="text-base font-semibold">{currentUser?.name || 'Guest User'}</p>
            <span className="bg-[#F3F4F6] w-6 h-6 rounded-full flex items-center justify-center">
              <img src={chevronDownIcon} alt="Chevron down" width={12} height={12} />
            </span>
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="w-64 p-0 z-50 border shadow-lg mt-2 rounded-[16px] bg-white overflow-hidden"
          align="end"
          sideOffset={0}
        >
          <div className="flex flex-col">
            {/* Profile Menu Items */}
            <div className="py-2">
              <button
                onClick={() => handleNavigate('/profile')}
                className="w-full text-left px-4 py-2 text-base hover:bg-gray-100 flex items-center gap-3 font-semibold text-gray hover:bg-[#DBD6FF66]"
              >
                <img src={profileIcon} alt="profile-icon" />
                Profile
              </button>
              <button
                onClick={() => handleNavigate('/profile/subscription')}
                className="w-full text-left px-4 py-2 text-base hover:bg-gray-100 flex items-center gap-3 font-semibold text-gray hover:bg-[#DBD6FF66]"
              >
                <img src={crownIcon} alt="crown-icon" />
                Subscription
              </button>
              <button
                onClick={() => handleNavigate('/profile/terminology')}
                className="w-full text-left px-4 py-2 text-base hover:bg-gray-100 flex items-center gap-3 font-semibold text-gray hover:bg-[#DBD6FF66]"
              >
                <img src={terminologyIcon} alt="terminology-icon" />
                Terminology
              </button>
              <button
                onClick={handleLogout}
                className="w-full text-left px-4 py-2 text-base text-red-500 hover:bg-gray-100 flex items-center gap-3 font-semibold text-gray hover:bg-[#DBD6FF66]"
              >
                <img src={logoutIcon} alt="logout-icon" />
                Logout
              </button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ProfileDropdown;
