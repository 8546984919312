// components/organisms/BudgetCreationForm.tsx
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';

import ColorSlider from '@/components/atoms/ColorSlider';
import DatePickerField from '@/components/atoms/DatePickerField';
import IconSelect from '@/components/atoms/IconSelect';
import InputField from '@/components/atoms/InputField';
import SelectField from '@/components/atoms/SelectField';
import SliderField from '@/components/atoms/SliderField';
import SwitchField from '@/components/atoms/SwitchField';
import TextAreaField from '@/components/atoms/TextAreaField';

import { BUDGET_ICONS } from '@/utils/budgetIcons';
import { BudgetType } from '@/services/types/enums/budgets.enum';
import * as z from 'zod';

import euroIcon from '@/assets/icons/euro.svg';

// Simple schema - just validating required fields
const budgetFormSchema = z.object({
  budgetName: z.string().min(1, { message: 'Name is required' }),
  budgetType: z.string().min(1, { message: 'Type is required' }),
  budgetIcon: z.string(),
  budgetColor: z.string(),
  description: z.string().min(1, { message: 'Description is required' }),
  startDate: z.date(),
  endDate: z.date(),
  period: z.string(),
  currency: z.string(),
  autoRenewal: z.boolean(),
  bmev: z.union([z.string(), z.number()]),
  bmevThreshold: z.number(),
  btrt: z.union([z.string(), z.number()]),
  btrtThreshold: z.number()
});

type BudgetFormValues = z.infer<typeof budgetFormSchema>;

const periods = [
  { value: 'one_month', label: '1 Month' },
  { value: 'six_month', label: '6 Month' },
  { value: 'one_year', label: '1 Year' }
];

const currencies = [
  { value: 'USD', label: 'US Dollar' },
  { value: 'EUR', label: 'Euro' },
  { value: 'GBP', label: 'British Pound' }
];

// const budgetTypes = [
//   { value: BudgetType.PERSONAL, label: 'Personal' },
//   { value: BudgetType.BUSINESS, label: 'Business' },
//   { value: BudgetType.SHARED, label: 'Shared' }
// ];

interface BudgetCreationFormProps {
  onSubmit?: (data: any) => void;
  className?: string;
  initialValues?: Partial<BudgetFormValues>;
}

const BudgetCreationForm: React.FC<BudgetCreationFormProps> = ({ onSubmit, className, initialValues }) => {
  const defaultValues: BudgetFormValues = {
    budgetName: '',
    budgetType: '',
    budgetIcon: Object.keys(BUDGET_ICONS)[0] || '',
    budgetColor: '#FF0000',
    description: '',
    startDate: new Date(),
    endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    currency: 'EUR',
    period: 'one_month',
    autoRenewal: false,
    bmev: '',
    bmevThreshold: 50,
    btrt: '',
    btrtThreshold: 50
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm<BudgetFormValues>({
    resolver: zodResolver(budgetFormSchema),
    defaultValues
  });

  // Set initial values when they're provided (for edit mode)
  useEffect(() => {
    if (initialValues) {
      reset({ ...defaultValues, ...initialValues });
    }
  }, [initialValues, reset]);

  const selectedIcon = watch('budgetIcon');
  const selectedColor = watch('budgetColor');

  // Add this function inside your component to calculate the period based on start and end dates
  const calculatePeriod = (startDate: Date, endDate: Date): string => {
    const diffMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

    if (diffMonths <= 1) {
      return 'one_month';
    } else if (diffMonths <= 6) {
      return 'six_month';
    } else {
      return 'one_year';
    }
  };

  // Replace your existing useEffect for date/period syncing with this one
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const startDate = value.startDate as Date;
      const endDate = value.endDate as Date;
      const currentPeriod = value.period as string;

      // If start date changed, update end date based on current period
      if (name === 'startDate' && startDate && currentPeriod) {
        let newEndDate = new Date(startDate);

        if (currentPeriod === 'one_month') {
          newEndDate.setMonth(startDate.getMonth() + 1);
        } else if (currentPeriod === 'six_month') {
          newEndDate.setMonth(startDate.getMonth() + 6);
        } else if (currentPeriod === 'one_year') {
          newEndDate.setFullYear(startDate.getFullYear() + 1);
        }

        setValue('endDate', newEndDate);
      }

      // If end date changed, update period based on the date difference
      else if (name === 'endDate' && startDate && endDate) {
        const newPeriod = calculatePeriod(startDate, endDate);
        if (newPeriod !== currentPeriod) {
          setValue('period', newPeriod);
        }
      }

      // If period changed, update end date based on start date and new period
      else if (name === 'period' && startDate && currentPeriod) {
        let newEndDate = new Date(startDate);

        if (currentPeriod === 'one_month') {
          newEndDate.setMonth(startDate.getMonth() + 1);
        } else if (currentPeriod === 'six_month') {
          newEndDate.setMonth(startDate.getMonth() + 6);
        } else if (currentPeriod === 'one_year') {
          newEndDate.setFullYear(startDate.getFullYear() + 1);
        }

        setValue('endDate', newEndDate);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const onSubmitForm = (data: BudgetFormValues) => {
    console.log('Form submitted with data:', data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  return (
    <form id="budget-creation-form" className={`space-y-6 ${className}`} onSubmit={handleSubmit(onSubmitForm)}>
      <div className="space-y-6 w-full flex flex-col items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <InputField
          className="flex-1 w-full"
          label="Name of budget"
          placeholder="Enter budget name..."
          name="budgetName"
          type="text"
          register={register}
          error={errors.budgetName}
        />
        <InputField
          className="flex-1 w-full"
          label="Budget type"
          placeholder="Enter budget type..."
          name="budgetType"
          type="text"
          register={register}
          error={errors.budgetType}
        />
        {/* <SelectField
          className="flex-1 w-full"
          label="Budget type"
          placeholder="Select budget type..."
          name="budgetType"
          options={budgetTypes}
          register={register}
          value={watch('budgetType')}
          onChange={(value) => setValue('budgetType', value as BudgetType)}
          error={errors.budgetType}
        /> */}
      </div>
      <div className="space-y-6 w-full flex flex-col items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <div className="flex-1">
          <IconSelect
            name="budgetIcon"
            value={selectedIcon}
            onChange={(value) => setValue('budgetIcon', value)}
            icons={BUDGET_ICONS}
            // error={errors.budgetIcon}
          />
          <ColorSlider
            className="mt-4"
            value={selectedColor}
            onChange={(color) => setValue('budgetColor', color)}
            title="Select a colour for your budget icon"
          />
        </div>
        <TextAreaField
          name="description"
          label="Budget Description"
          placeholder="Enter description..."
          register={register}
          className="flex-1 w-full"
          error={errors.description}
        />
      </div>
      <div className="flex items-center gap-3">
        <div className="flex-1 flex items-start gap-3">
          <DatePickerField
            name="startDate"
            label="Budget starting date"
            register={register}
            value={watch('startDate')}
            onChange={(date) => date && setValue('startDate', date)}
            className="flex-1"
            error={errors.startDate}
          />
          <DatePickerField
            name="endDate"
            label="Budget ending date"
            register={register}
            value={watch('endDate')}
            onChange={(date) => date && setValue('endDate', date)}
            className="flex-1"
            error={errors.endDate}
          />
        </div>
        <SelectField
          name="period"
          options={periods}
          value={watch('period')}
          onChange={(value) => {
            setValue('period', value);
          }}
          variant="icon-only"
          className="md:flex-1 md:hidden"
        />
        <SelectField
          name="period"
          label="Select Period"
          placeholder="Select Period..."
          options={periods}
          register={register}
          value={watch('period')}
          onChange={(value) => {
            setValue('period', value);
          }}
          className="hidden md:block flex-1"
        />
      </div>
      <div className="space-y-6 w-full flex flex-col md:items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <SelectField
          name="currency"
          label="Currency"
          placeholder="Select Currency"
          options={currencies}
          register={register}
          value={watch('currency')}
          onChange={(value) => {
            setValue('currency', value);
          }}
          className="flex-1"
          error={errors.currency}
        />
        <SwitchField
          name="autoRenewal"
          label="Automatic Renewal"
          register={register}
          checked={watch('autoRenewal')}
          onCheckedChange={(checked) => setValue('autoRenewal', checked)}
          className="flex-1"
        />
      </div>

      <div className="space-y-6 w-full flex flex-col md:items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <div className="w-full flex-1 space-y-6">
          <InputField
            name="bmev"
            label="Budget maximum expense value (BMEV)"
            placeholder="e.g 100000"
            type="text"
            icon={euroIcon}
            iconAlt="Euro"
            register={register}
            error={errors.bmev}
          />
          <SliderField
            name="bmevThreshold"
            label="Alerting threshold of BMEV"
            register={register}
            value={[watch('bmevThreshold')]}
            onValueChange={([value]) => setValue('bmevThreshold', value)}
            min={0}
            max={100}
            step={1}
            unit="%"
            error={errors.bmevThreshold}
          />
        </div>
        <div className="w-full flex-1 space-y-6">
          <InputField
            name="btrt"
            label="Budget total revenue target (BTRT)"
            placeholder="e.g 100000"
            type="text"
            icon={euroIcon}
            iconAlt="Euro"
            register={register}
            error={errors.btrt}
          />
          <SliderField
            name="btrtThreshold"
            label="Alerting threshold of BTRT"
            register={register}
            value={[watch('btrtThreshold')]}
            onValueChange={([value]) => setValue('btrtThreshold', value)}
            min={0}
            max={100}
            step={1}
            unit="%"
            error={errors.btrtThreshold}
          />
        </div>
      </div>
    </form>
  );
};

export default BudgetCreationForm;
