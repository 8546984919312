import React, { useState, useRef, useEffect } from 'react';
import { BudgetCard } from '@/components/atoms/BudgetCard'; // Adjust the import path if needed

// Custom carousel with wider active card using BudgetCard component
const BudgetCarousel = ({ budgets, onBudgetSelect }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [isTouching, setIsTouching] = useState(false);
  const [translateX, setTranslateX] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  // Detect mobile view
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 880);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Calculate dimensions and update translate when current index changes
  useEffect(() => {
    updateCarouselPosition();
  }, [currentIndex, isMobile]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      updateCarouselPosition();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentIndex, isMobile]);

  // Update carousel position based on current index with enhanced preview
  const updateCarouselPosition = () => {
    if (containerRef.current && trackRef.current) {
      const containerWidth = containerRef.current.clientWidth;

      if (isMobile) {
        // Mobile view: one full card
        const cardWidth = containerWidth * 0.8; // 80% of container width
        const centerOffset = (containerWidth - cardWidth) / 2;
        const targetPosition = cardWidth * currentIndex - centerOffset;
        setTranslateX(-targetPosition);
      } else {
        // Desktop view: enhanced preview visibility with wider active card
        const cardWidth = containerWidth * 0.25; // Base card width

        // Calculate offset to center current card
        const centerOffset = (containerWidth - cardWidth) / 2;

        // Calculate target position - adjust for wider active card
        const targetPosition = cardWidth * currentIndex - centerOffset;

        setTranslateX(-targetPosition);
      }
    }
  };

  // Navigate to next slide
  const nextSlide = () => {
    if (currentIndex < budgets.length - 1) {
      setCurrentIndex(currentIndex + 1);
      if (onBudgetSelect) {
        onBudgetSelect(budgets[currentIndex + 1]);
      }
    }
  };

  // Navigate to previous slide
  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      if (onBudgetSelect) {
        onBudgetSelect(budgets[currentIndex - 1]);
      }
    }
  };

  // Touch handlers for mobile swiping
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
    setIsTouching(true);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isTouching) return;

    const touchCurrentX = e.touches[0].clientX;
    const diff = touchCurrentX - touchStartX;

    if (containerRef.current && trackRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const cardWidth = isMobile ? containerWidth * 0.8 : containerWidth * 0.25;
      const centerOffset = (containerWidth - cardWidth) / 2;
      const baseTranslateX = -(cardWidth * currentIndex - centerOffset);

      // Add resistance at edges
      if ((currentIndex === 0 && diff > 0) || (currentIndex >= budgets.length - 1 && diff < 0)) {
        setTranslateX(baseTranslateX + diff * 0.2);
      } else {
        setTranslateX(baseTranslateX + diff);
      }
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (!isTouching) return;
    setIsTouching(false);

    const touchEndX = e.changedTouches[0].clientX;
    const diff = touchEndX - touchStartX;

    // Determine if we should navigate based on swipe distance
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        prevSlide();
      } else {
        nextSlide();
      }
    } else {
      // Reset position if swipe wasn't far enough
      updateCarouselPosition();
    }
  };

  // Handle click on a specific card
  const handleCardClick = (index: number) => {
    setCurrentIndex(index);
    if (onBudgetSelect) {
      onBudgetSelect(budgets[index]);
    }
  };

  return (
    <div
      className="custom-carousel-container relative w-full bg-[#F3F1FF] py-[2.85rem] rounded-[16px] border border-primary"
      ref={containerRef}
    >
      {(!budgets || budgets.length === 0) && <p className="text-center py-4">Not found</p>}

      <style>{`
        .custom-carousel-container {
          position: relative;
          overflow: hidden;
          width: 100%;
          padding: 25px 0;
        }

        .custom-carousel-track {
          display: flex;
          transition: transform 0.3s ease;
          align-items: center;
        }

        /* Base card styling */
        .custom-carousel-card {
          flex: 0 0 25%;
          box-sizing: border-box;
          padding: 0 0px;
          transition: all 0.3s ease;
        }

        /* Wider active card */
        .custom-carousel-card.active-card {
          flex: 0 0 29%; /* Slightly wider than regular cards */
          padding: 0 20px; /* Reduced padding to maintain even gaps */
        }

        /* Adjusted padding for cards adjacent to active card */
        .custom-carousel-card.card-before-active,
        .custom-carousel-card.card-after-active {
          padding: 0 6px 0 10px;
        }

        .custom-carousel-card.card-after-active {
          padding: 0 6px 0 6px;
        }

        /* Card wrapper for scaling */
        .carousel-card-wrapper {
          transition: all 0.3s ease;
          transform: scale(0.95);
          transform-origin: center center;
        }

        .carousel-card-wrapper.active {
          transform: scale(1.1);
        }

        .carousel-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 40px;
          height: 40px;
          background: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          z-index: 10;
          cursor: pointer;
          border: none;
        }

        .carousel-button-prev {
          left: 10px;
        }

        .carousel-button-next {
          right: 10px;
        }

        @media (max-width: 768px) {
          .custom-carousel-card {
            flex: 0 0 80%;
          }
          .custom-carousel-card.active-card {
            flex: 0 0 80%;
          }
        }
      `}</style>

      <div
        className="custom-carousel-track"
        ref={trackRef}
        style={{
          transform: `translateX(${translateX}px)`,
          transition: isTouching ? 'none' : 'transform 0.3s ease'
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {budgets &&
          budgets.map((budget: any, index: number) => (
            <div
              key={budget.id || index}
              className={`custom-carousel-card ${
                index === currentIndex
                  ? 'active-card'
                  : index === currentIndex - 1
                    ? 'card-before-active'
                    : index === currentIndex + 1
                      ? 'card-after-active'
                      : ''
              }`}
            >
              <div className={`carousel-card-wrapper ${index === currentIndex ? 'active' : ''}`}>
                <BudgetCard budget={budget} isActive={index === currentIndex} onClick={() => handleCardClick(index)} />
              </div>
            </div>
          ))}
      </div>

      {currentIndex > 0 && (
        <button className="carousel-button carousel-button-prev" onClick={prevSlide} aria-label="Previous slide">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      )}

      {currentIndex < budgets.length - 1 && (
        <button className="carousel-button carousel-button-next" onClick={nextSlide} aria-label="Next slide">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 6L15 12L9 18" stroke="#666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default BudgetCarousel;
