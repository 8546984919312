// pages/BankAccountAddPage.tsx
import { Button } from '@/components/ui/button';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BankAccountCreationForm from '@/components/organisms/BankAccountCreationForm';
import { bankAccountService } from '@/services/bank-accounts.service';
import { BankAccountFormValues } from '@/services/types/bank-accounts.types';
import DashboardTemplate from '@/components/templates/DashboardTemplate';
import toast from 'react-hot-toast';
import InfoModal from '@/components/molecules/InfoModal';

const BankAccountAddPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [bankAccountId, setBankAccountId] = useState<string | null>(null);
  const { budgetId } = useParams();
  const navigate = useNavigate();

  if (!budgetId) {
    console.error('Budget ID is missing from URL parameters');
    return <div>Error: Budget ID is required</div>;
  }

  const handleSubmit = async (data: BankAccountFormValues) => {
    setIsSubmitting(true);
    try {
      // Transform form data to match API requirements
      const bankAccountData = {
        accountName: data.accountName,
        bankName: data.bankName,
        bankCountry: data.country,
        accountNumber: data.accountNumber,
        initialAmount: typeof data.initialAmount === 'string' ? parseFloat(data.initialAmount) : data.initialAmount
      };

      // Call API service to create bank account
      const response = await bankAccountService.createBankAccount(budgetId, bankAccountData);

      // Store the created bank account ID
      if (response.data && response.data.budget_id) {
        setBankAccountId(response.data.budget_id);
      }

      // Show success modal
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error creating bank account:', error);
      toast.error('Failed to create bank account. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate(`/budgets/${budgetId}`);
  };

  const handleButtonClick = () => {
    // Trigger form submission
    const form = document.getElementById('bank-account-creation-form') as HTMLFormElement;
    if (form) {
      form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  const handleGoToBankAccount = () => {
    setShowSuccessModal(false);
    // Navigate to the specific bank account overview page
    if (bankAccountId) {
      navigate(`/budgets/${budgetId}/bank-accounts/${bankAccountId}`);
    } else {
      // Fallback to the bank accounts list if we don't have the ID
      navigate(`/budgets/${budgetId}/bank-accounts`);
    }
  };

  return (
    <DashboardTemplate>
      <div className="bg-[#FBFBFF] p-4 flex flex-col flex-1 md:bg-white rounded-[16px] border">
        <h2 className="text-center font-bold mb-5 md:text-left md:text-[24px] md:mb-6">Create your Bank Account</h2>

        {/* FORM */}
        <BankAccountCreationForm onSubmit={handleSubmit} className="mb-6 flex-1" isSubmitting={isSubmitting} />

        {/* FOOTER */}
        <footer className="py-2 bg-gradient-to-b from-[#F0EFFD]/0 from-0% to-[#F0EFFD] to-[17.74%] md:bg-white md:bg-none md:from-transparent md:to-transparent md:flex md:justify-end">
          <Button
            className="hidden md:block rounded-[24px] text-base font-semibold px-6 h-[44px] text-[16px] w-full md:w-fit text-[#E8507E]"
            variant={'text'}
            onClick={handleCancel}
            disabled={isSubmitting}
            type="button"
          >
            Cancel
          </Button>
          <Button
            className="rounded-[24px] text-base font-semibold px-6 h-[44px] text-[16px] w-full md:w-fit"
            onClick={handleButtonClick}
            disabled={isSubmitting}
            type="button"
          >
            {isSubmitting ? 'CREATING...' : 'CREATE'}
          </Button>
        </footer>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <InfoModal
          isOpen={showSuccessModal}
          variant="success"
          title="Bank Account Created"
          message={<p>You have successfully created a Bank Account!</p>}
          buttonText="Go to Bank Account"
          onButtonClick={handleGoToBankAccount}
        />
      )}
    </DashboardTemplate>
  );
};

export default BankAccountAddPage;
