import DashboardTemplate from '@/components/templates/DashboardTemplate';
import React from 'react';
import { Outlet } from 'react-router-dom';

const BankAccountPage = () => {
  return (
    <DashboardTemplate>
      <Outlet />
    </DashboardTemplate>
  );
};

export default BankAccountPage;
