import { z } from 'zod';

// Zod schema that matches the backend DTO requirements
export const bankAccountSchema = z.object({
  accountName: z.string().min(1, { message: 'Account name is required' }),
  bankName: z.string().min(1, { message: 'Bank name is required' }),
  bankCountry: z.string().min(1, { message: 'Bank country is required' }),
  accountNumber: z.string().min(1, { message: 'Account number is required' }),
  initialAmount: z.number().min(100000, { message: 'Initial amount must be at least 100,000' })
});

// Form schema for the frontend form
export const bankAccountFormSchema = z.object({
  accountName: z.string().min(1, { message: 'Bank account name is required' }),
  bankName: z.string().min(1, { message: 'Bank name is required' }),
  country: z.string().min(1, { message: 'Country is required' }),
  accountNumber: z.string().min(1, { message: 'Account number is required' }),
  initialAmount: z.union([
    z.number().min(100000, { message: 'Initial amount must be at least 100,000' }),
    z
      .string()
      .min(1, { message: 'Initial amount is required' })
      .refine((val) => !isNaN(Number(val)) && Number(val) >= 100000, {
        message: 'Initial amount must be at least 100,000'
      })
  ])
});

export type BankAccountFormValues = z.infer<typeof bankAccountFormSchema>;

// Backend API interfaces
export interface BankAccount {
  budget_id: string;
  accountName: string;
  bankName: string;
  bankCountry: string;
  accountNumber: string;
  initialAmount: number;
  created_at?: string;
  updated_at?: string;
  _id?: string;
}

export type BankAccountCreationData = z.infer<typeof bankAccountSchema>;

export interface BankAccountCreationResponse {
  success: boolean;
  message: string;
  data: BankAccount;
}

export type BankAccountUpdateData = Partial<BankAccountCreationData>;

export interface BankAccountUpdateResponse {
  success: boolean;
  message: string;
  data: BankAccount;
}

export interface BankAccountResponse {
  success: boolean;
  message: string;
  data: BankAccount;
}

export interface BankAccountsResponse {
  success: boolean;
  message: string;
  data: BankAccount[];
}
