import { store } from '@/store';
import { setUser } from '@/store/slices/auth';

export const formatNumberToK = (number: string) => {
  const num = parseFloat(number);
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(0) + 'k';
  }
  return num.toString();
};

export const setUserInStore = (userToJson: any): void => {
  const { displayName, email, phoneNumber, photoURL, uid } = userToJson;

  const nameParts = displayName.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(' ');

  // store user in Redux
  store.dispatch(setUser({ firstName, lastName, name: displayName, email, phoneNumber, photoURL, id: uid }));
};

/**
 * Returns the full currency name and symbol for a given currency code
 * @param currencyCode - The ISO currency code (e.g., 'USD', 'EUR')
 * @returns The formatted currency name and symbol (e.g., 'US Dollar ($)')
 */
export const getCurrencyInfo = (currencyCode: string): string => {
  const currencies: Record<string, { name: string; symbol: string }> = {
    USD: { name: 'US Dollar', symbol: '$' },
    EUR: { name: 'Euro', symbol: '€' },
    GBP: { name: 'British Pound', symbol: '£' },
    JPY: { name: 'Japanese Yen', symbol: '¥' },
    CAD: { name: 'Canadian Dollar', symbol: 'CA$' },
    AUD: { name: 'Australian Dollar', symbol: 'A$' },
    CHF: { name: 'Swiss Franc', symbol: 'CHF' },
    CNY: { name: 'Chinese Yuan', symbol: '¥' },
    INR: { name: 'Indian Rupee', symbol: '₹' },
    BRL: { name: 'Brazilian Real', symbol: 'R$' }
  };

  // Normalize the currency code (uppercase and trim)
  const normalizedCode = currencyCode.trim().toUpperCase();

  // Return formatted string if currency exists, otherwise return the code
  if (currencies[normalizedCode]) {
    const { name, symbol } = currencies[normalizedCode];
    return `${name} (${symbol})`;
  }

  return normalizedCode; // Return the code itself if not found
};

/**
 * Converts a date string from YYYY-MM-DD format to MM/DD/YYYY format
 * @param dateString - Date string in YYYY-MM-DD format (e.g., '2025-04-02')
 * @returns Date string in MM/DD/YYYY format (e.g., '04/02/2025')
 */
export const formatDate = (dateString: string): string => {
  // Return empty string if dateString is empty or invalid
  if (!dateString) return '';

  try {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Check if date is valid
    if (isNaN(date.getTime())) {
      return '';
    }

    // Get month, day, and year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    // Return formatted date string (MM/DD/YYYY)
    return `${month}/${day}/${year}`;
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};
