import React from 'react';
import { LabelList, Pie, PieChart } from 'recharts';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';

interface ChartDataPoint {
  key: string;
  value: number;
  fill: string;
}

interface PieGraphProps {
  title?: string;
  titleValue?: string;
  chartConfig: ChartConfig;
  chartData: ChartDataPoint[];
  variant?: string;
  className?: string;
}

// Custom formatter for labels - hides 0% values
const customLabelFormatter = (value: number) => {
  return value === 0 ? '' : `${value}%`;
};

const PieGraph: React.FC<PieGraphProps> = ({
  title,
  titleValue,
  chartConfig,
  chartData,
  variant = 'default',
  className = ''
}) => {
  // generating legend items based on chartData and chartConfig
  const legendItems = chartData.map((item) => ({
    key: item.key,
    label: chartConfig[item.key]?.label || item.key,
    color: chartConfig[item.key]?.color || item.fill
  }));

  return (
    <div className={`${className}`}>
      <p
        className={`${variant === 'primary' ? 'text-primary' : 'text-secondary'} text-xs md:text-sm font-medium md:leading-[17px]`}
      >
        {title}
      </p>
      <h3
        className={`${variant === 'primary' ? 'text-primary' : ''} font-bold text-xl md:text-[28px] leading-[24.3px] md:leading-[34px]`}
      >
        {titleValue}
      </h3>

      <ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[327px] mt-1 md:min-h-[180px]">
        <PieChart>
          <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
          <Pie data={chartData} dataKey="value" nameKey="key" className="border w-full" outerRadius={68}>
            <LabelList
              dataKey="value"
              className="fill-background"
              stroke="none"
              fontSize={12}
              formatter={customLabelFormatter}
            />
          </Pie>
        </PieChart>
      </ChartContainer>
      <div className="pl-0 mt-[0.35rem] md:flex md:flex-col md:items-center">
        {legendItems.map(({ key, label, color }) => (
          <div key={key} className="flex items-center gap-[0.4rem] mb-[2px]">
            <div
              className="h-2 w-2 md:h-[9px] md:w-[9px] shrink-0 rounded-full"
              style={{
                backgroundColor: color
              }}
            />
            <span className="text-xs md:text-sm text-secondary">{typeof label === 'string' ? label : key}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export type { ChartDataPoint, PieGraphProps };

export default PieGraph;
