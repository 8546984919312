import React from 'react';
import defaultAvatar from '@/assets/images/default-user.png';

interface ProfileNameProps {
  profilePic: string;
  name: string;
}

const ProfileName: React.FC<ProfileNameProps> = ({ profilePic, name }) => {
  console.log('profilePic:', profilePic);
  return (
    <div className="flex items-center gap-5 pr-5">
      <img src={profilePic} alt="default avatar" className="w-[80px] h-[80px] rounded-full overflow-hidden" />
      <p className="text-primary text-[22px] font-bold leading-[22px] uppercase">{name}</p>
    </div>
  );
};

export default ProfileName;
