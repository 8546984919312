import MobileAppBar from '@/components/molecules/MobileAppBar';
import DashboardTemplate from '@/components/templates/DashboardTemplate';
import React, { useEffect, useState } from 'react';
import searchIcon from '@/assets/icons/search.svg';
import { Outlet, useLocation } from 'react-router-dom';
import { BudgetSection } from '@/components/organisms/BudgetsSection';
import { budgetService } from '@/services/budget.service';

// Type for the API response
interface BudgetData {
  bmev: number;
  bmev_alert_threshold: number;
  bmev_used: number;
  btrt: number;
  btrt_alert_threshold: number;
  btrt_used: number;
  color_hex: string;
  createdAt: string;
  currency: string;
  description: string;
  end_date: string;
  icon_id: string;
  is_auto_renewal: boolean;
  name: string;
  start_date: string;
  type: string;
  updatedAt: string;
  user_id: string;
  _v: number;
  id: string;
  _id: string;
}

const BudgetPage = () => {
  const location = useLocation();

  if (location.pathname === '/budget') {
    return (
      <DashboardTemplate>
        <MobileAppBar title="Budget" variant="primary" onRightIconClick={() => {}} rightIcon={searchIcon} />
        <div className="p-5">Budget Page</div>
      </DashboardTemplate>
    );
  }

  return (
    <DashboardTemplate>
      {location.pathname === '/budgets' ? <BudgetSection /> : ''}
      <Outlet />
    </DashboardTemplate>
  );
};

export default BudgetPage;
