import { cn } from '@/lib/utils';
import budgetIcon from '@/assets/icons/budget-white.svg';

interface BudgetCardProps {
  budget: any;
  isActive?: boolean;
  onClick?: () => void;
}

export const BudgetCard: React.FC<BudgetCardProps> = ({ budget, isActive = false, onClick }) => {
  // console.log('isActive:', isActive);
  return (
    <div
      className={cn(
        'w-full cursor-pointer rounded-2xl transition-all duration-300'
        // Fixed minimum height
      )}
      style={{ backgroundColor: budget?.color_hex || '#D9D9D9`' }}
      onClick={onClick}
    >
      <div className="p-[0.65rem] text-white h-full flex flex-col">
        <div className="mb-2">
          <img src={budgetIcon} alt="" className="mb-1 h-[20px]" />
          <h3 className="text-[1rem] font-medium leading-[23px]">{budget?.name || '-'}</h3>
          <p className="text-xs my-0 p-0 mb-0 min-h-[24px]">
            {budget?.description && budget.description.length > 60
              ? `${budget.description.substring(0, 60)}...`
              : budget?.description || '-'}
          </p>
        </div>

        <div className="flex items-start justify-between mt-auto">
          <div className="flex-1">
            <p className="opacity-80 text-[10px] leading-[12px]">BMEV</p>
            <p className="font-bold text-xs">{budget?.bmev}</p>
          </div>
          <div className="flex-1 ml-3">
            <p className="opacity-80 text-[10px] leading-[12px]">BTRT</p>
            <p className="font-bold text-xs">{budget?.btrt}</p>
          </div>
        </div>

        <div className="flex items-start justify-between mt-3">
          <div className="flex-1">
            <p className="font-medium opacity-80 text-[10px] leading-[15px]">Remaining BMEV</p>
            <p className="font-bold text-[1rem]">{Number(budget?.bmev) - Number(budget?.bmev_used)}</p>
          </div>
          <div className="flex-1 ml-3">
            <p className="font-medium opacity-80 text-[10px] leading-[15px]">Achieved BTRT</p>
            <p className="font-bold text-[1rem]">{budget?.btrt_achieved}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
