import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import searchIcon from '@/assets/icons/search.svg';
import bellIcon from '@/assets/icons/bell.svg';
import ProfileDropdown from '@/components/molecules/ProfileDropdown';

// Import all the icons for different pages
import dashboardIcon from '@/assets/icons/dashboard.svg';
// Import other icons as needed
import budgetsIcon from '@/assets/icons/budgets.svg';
import profileIcon from '@/assets/icons/profile.svg';
import commitmentIcon from '@/assets/icons/commitment.svg';
import invoiceIcon from '@/assets/icons/invoice.svg';

// Import the routes config
import { PAGE } from '@/routes';

const DynamicHeader = () => {
  const location = useLocation();

  // Get current path and determine the title and icon
  const { title, icon } = useMemo(() => {
    const path = location.pathname;

    // Default title and icon
    let title: string | undefined = 'Dashboard';
    let icon = dashboardIcon;

    // Check main routes
    if (path.startsWith(PAGE.DASHBOARD.path)) {
      title = PAGE.DASHBOARD.navTitle;
      icon = dashboardIcon;
    } else if (path.startsWith(PAGE.BUDGETS.path)) {
      title = PAGE.BUDGETS.navTitle;
      icon = budgetsIcon;
    } else if (path.startsWith(PAGE.PROFILE.path)) {
      title = PAGE.PROFILE.navTitle;
      icon = profileIcon;
    } else if (path.startsWith(PAGE.COMMITMENT.path)) {
      title = PAGE.COMMITMENT.navTitle;
      icon = commitmentIcon;
    } else if (path.startsWith(PAGE.INVOICE.path)) {
      title = PAGE.INVOICE.navTitle;
      icon = invoiceIcon;
    }

    if (!title) {
      title = 'Dashboard';
    }

    return { title, icon };
  }, [location.pathname]);

  return (
    <header className="sticky w-full top-0 z-40 h-[64px] shadow-[0px_2px_4px_0px_#00000040] bg-white flex items-center justify-between px-7">
      {/* DYNAMIC PAGE TITLE */}
      <h2 className="flex items-center gap-2 font-semibold">
        <img src={icon} alt={`${title.toLowerCase()} icon`} />
        <span>{title}</span>
      </h2>

      <div className="flex items-center gap-9">
        {/* SEARCH */}
        <div className="relative flex bg-lightGray rounded-[16px] overflow-hidden h-[40px] w-[303px]">
          <img className="absolute top-[50%] translate-y-[-50%] left-[15px]" src={searchIcon} alt="search icon" />

          <input
            className="bg-transparent text-xs font-medium w-full placeholder:text-secondary placeholder:text-xs placeholder:font-medium pl-[43px] rounded-[16px]"
            type="text"
            placeholder="Search for Budgets, Accounts, etc..."
          />
        </div>

        {/* NOTIFICATIONS BELL */}
        <button>
          <img src={bellIcon} alt="bell icon" />
        </button>

        {/* PROFILE DROPDOWN */}
        <ProfileDropdown />
      </div>
    </header>
  );
};

export default DynamicHeader;
