import { apiService } from '@/api/apiConfig';
import {
  BankAccountCreationData,
  BankAccountCreationResponse,
  BankAccountUpdateData,
  BankAccountUpdateResponse,
  BankAccountResponse,
  BankAccountsResponse
} from '@/services/types/bank-accounts.types';

const handleBankAccountError = (error: any): Error => {
  // Check if the error has a response with data
  if (error.response?.data?.message) {
    return new Error(`Bank account operation failed: ${error.response.data.message}`);
  }
  return error instanceof Error ? error : new Error('Bank account operation failed');
};

export const bankAccountService = {
  createBankAccount: async (
    budgetId: string,
    bankAccountData: BankAccountCreationData
  ): Promise<BankAccountCreationResponse> => {
    try {
      return await apiService.request<BankAccountCreationResponse>({
        method: 'POST',
        url: `/budgets/${budgetId}/bank-accounts`,
        data: bankAccountData,
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      throw handleBankAccountError(error);
    }
  },

  getBankAccount: async (budgetId: string, bankAccountId: string): Promise<BankAccountResponse> => {
    try {
      return await apiService.request<BankAccountResponse>({
        method: 'GET',
        url: `/budgets/${budgetId}/bank-accounts/${bankAccountId}`
      });
    } catch (error) {
      throw handleBankAccountError(error);
    }
  },

  getBankAccounts: async (budgetId: string): Promise<BankAccountsResponse> => {
    try {
      return await apiService.request<BankAccountsResponse>({
        method: 'GET',
        url: `/budgets/${budgetId}/bank-accounts`
      });
    } catch (error) {
      throw handleBankAccountError(error);
    }
  },

  updateBankAccount: async (
    budgetId: string,
    bankAccountId: string,
    bankAccountData: BankAccountUpdateData
  ): Promise<BankAccountUpdateResponse> => {
    try {
      return await apiService.request<BankAccountUpdateResponse>({
        method: 'PATCH',
        url: `/budgets/${budgetId}/bank-accounts/${bankAccountId}`,
        data: bankAccountData,
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      throw handleBankAccountError(error);
    }
  },

  deleteBankAccount: async (budgetId: string, bankAccountId: string): Promise<void> => {
    try {
      return await apiService.request<void>({
        method: 'DELETE',
        url: `/budgets/${budgetId}/bank-accounts/${bankAccountId}`
      });
    } catch (error) {
      throw handleBankAccountError(error);
    }
  }
};
