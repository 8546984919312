// components/organisms/BankAccountCreationForm.tsx
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import * as z from 'zod';

import InputField from '@/components/atoms/InputField';
import euroIcon from '@/assets/icons/euro.svg';

// Import the form schema from types
import { bankAccountFormSchema } from '@/services/types/bank-accounts.types';

import { BankAccountFormValues } from '@/services/types/bank-accounts.types';

interface BankAccountCreationFormProps {
  onSubmit?: (data: BankAccountFormValues) => void;
  className?: string;
  isSubmitting?: boolean;
}

const BankAccountCreationForm: React.FC<BankAccountCreationFormProps> = ({
  onSubmit,
  className,
  isSubmitting = false
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<BankAccountFormValues>({
    resolver: zodResolver(bankAccountFormSchema),
    defaultValues: {
      accountName: '',
      bankName: '',
      country: '',
      accountNumber: '',
      initialAmount: ''
    }
  });

  const onSubmitForm = (data: BankAccountFormValues) => {
    console.log('Form submitted with data:', data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  return (
    <form id="bank-account-creation-form" className={`space-y-4 ${className}`} onSubmit={handleSubmit(onSubmitForm)}>
      <div className="space-y-6 w-full flex flex-col items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <InputField
          className="flex-1 w-full"
          label="Bank Account Name"
          placeholder="Enter bank account name..."
          name="accountName"
          type="text"
          register={register}
          error={errors.accountName}
          disabled={isSubmitting}
          size="sm"
        />
        <InputField
          className="flex-1 w-full"
          label="Name of the Bank"
          placeholder="Enter bank name..."
          name="bankName"
          type="text"
          register={register}
          error={errors.bankName}
          disabled={isSubmitting}
          size="sm"
        />
      </div>

      <div className="space-y-6 w-full flex flex-col items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <InputField
          className="flex-1 w-full"
          label="Country of the Bank"
          placeholder="Enter Country..."
          name="country"
          type="text"
          register={register}
          error={errors.country}
          disabled={isSubmitting}
          size="sm"
        />
        <InputField
          className="flex-1 w-full"
          label="Bank Account Number"
          placeholder="Enter bank account number..."
          name="accountNumber"
          type="text"
          register={register}
          error={errors.accountNumber}
          disabled={isSubmitting}
          size="sm"
        />
      </div>

      <div className="space-y-6 w-full flex flex-col items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <div className="flex-1 w-full">
          <InputField
            name="initialAmount"
            label="Initial Amount"
            placeholder="e.g 100000"
            type="text"
            icon={euroIcon}
            iconAlt="Euro"
            register={register}
            error={errors.initialAmount}
            disabled={isSubmitting}
            size="sm"
          />
          <p className="text-xs text-gray-500 mt-1">Initial Amount must be bigger or equal to BMEV (€100,000)</p>
        </div>
        <div className="flex-1 w-full"></div>
      </div>
    </form>
  );
};

export default BankAccountCreationForm;
