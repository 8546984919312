import React, { useState } from 'react';
import binIcon from '@/assets/icons/bin.svg';
import budgetIcon from '@/assets/icons/budget.svg';
import editIcon from '@/assets/icons/edit.svg';
import { getCurrencyInfo } from '@/utils/helpers';

interface BudgetInfoCardProps {
  name?: string;
  type?: string;
  description?: string;
  currency?: string;
  bmev?: number;
  bmevThreshold?: number;
  btrt?: number;
  btrtThreshold?: number;
  startDate?: string;
  endDate?: string;
  isAutoRenewal?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

const BudgetInfoCard: React.FC<BudgetInfoCardProps> = ({
  name = 'BUDGET NAME',
  type = 'Company',
  description = '',
  currency = 'EUR',
  bmev = 100000,
  bmevThreshold = 80,
  btrt = 100000,
  btrtThreshold = 80,
  startDate = '01/01/2024',
  endDate = '12/31/2024',
  isAutoRenewal = true,
  onEdit,
  onDelete
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="hidden md:flex p-5 border w-[60%] border-lightGray rounded-[16px] bg-white flex-col gap-[1.3rem]">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <img src={budgetIcon} alt="budget-icon" width={40} height={40} />
          <div>
            <h4 className="font-semibold text-base md:text-[24px] md:mb-[0.3rem]">{name}</h4>
            <p className="text-secondary text-sm font-medium md:text-[18px]">{type}</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <button type="button" onClick={onEdit}>
            <img src={editIcon} alt="edit-icon" width={28} height={28} />
          </button>
          <button type="button" onClick={onDelete}>
            <img src={binIcon} alt="bin-icon" width={28} height={28} />
          </button>
        </div>
      </header>
      <div className="border border-primary rounded-[16px] p-4 bg-lightPurple flex-1 space-y-3">
        <p className="text-primary font-bold text-base mb-3">General Information</p>

        <div>
          <p className="text-sm text-secondary font-medium leading-[18px]">Description</p>
          <p className={`text-base font-medium leading-[24.5px] ${isExpanded ? '' : 'line-clamp-2'}`}>
            {description || 'No description provided'}
          </p>
          {!isExpanded && description && description.length > 40 && (
            <button className="text-sm text-primary" onClick={() => setIsExpanded(true)}>
              See More
            </button>
          )}
          {isExpanded && (
            <button className="text-sm text-primary" onClick={() => setIsExpanded(false)}>
              See Less
            </button>
          )}
        </div>

        <div>
          <p className="text-sm text-secondary font-medium leading-[18px]">Default Currency Type</p>
          <p className="text-base font-medium leading-[24.5px]">{getCurrencyInfo(currency)}</p>
        </div>

        <div className="flex gap-4">
          <div className="flex-1">
            <p className="text-sm text-secondary font-medium leading-[18px]">Budget maximum expense value (BMEV)</p>
            <p className="text-base font-medium leading-[24.5px]">
              {currency === 'EUR' ? '€' : '$'}
              {bmev.toLocaleString()}
            </p>
          </div>

          <div className="flex-1">
            <p className="text-sm text-secondary font-medium leading-[18px]">Alerting threshold of BMEV</p>
            <p className="text-base font-medium leading-[24.5px]">{bmevThreshold}%</p>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex-1">
            <p className="text-sm text-secondary font-medium leading-[18px]">Budget maximum expense value (BTRT)</p>
            <p className="text-base font-medium leading-[24.5px]">
              {currency === 'EUR' ? '€' : '$'}
              {btrt.toLocaleString()}
            </p>
          </div>

          <div className="flex-1">
            <p className="text-sm text-secondary font-medium leading-[18px]">Alerting threshold of BTRT</p>
            <p className="text-base font-medium leading-[24.5px]">{btrtThreshold}%</p>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex-1">
            <p className="text-sm text-secondary font-medium leading-[18px]">Duration</p>
            <p className="text-base font-medium leading-[24.5px]">
              {startDate} - {endDate}
            </p>
          </div>

          <div className="flex-1">
            <p className="text-sm text-secondary font-medium leading-[18px]">Automatic Renewal</p>
            <p className="text-base font-medium leading-[24.5px]">{isAutoRenewal ? 'On' : 'Off'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetInfoCard;
