import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import MobileAppBar from '@/components/molecules/MobileAppBar';
import BudgetCreationForm from '@/components/organisms/BudgetCreationForm';
import { Button } from '@/components/ui/button';
import gearIcon from '@/assets/icons/gear.svg';
import { budgetService } from '@/services/budget.service';
import { hslToHex, hexToHsl } from '@/utils/ColorConverter';
// import { Spinner } from '@/components/atoms/Spinner'; // Assuming you have a spinner component

const EditPage = () => {
  const navigate = useNavigate();
  const { budgetId } = useParams<{ budgetId: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [budgetData, setBudgetData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBudget = async () => {
      if (!budgetId) {
        setError('Budget ID is missing');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const response = await budgetService.getBudget(budgetId);
        const budget = response.data;

        // Transform the API response to match form field names
        const formattedBudget = {
          budgetName: budget.name,
          budgetType: budget.type,
          budgetIcon: budget.icon_id,
          budgetColor: hexToHsl(budget.color_hex), // Convert hex to HSL for the slider
          description: budget.description || '',
          startDate: new Date(budget.start_date),
          endDate: new Date(budget.end_date),
          period: budget.period || 'one_month',
          currency: budget.currency,
          autoRenewal: budget.is_auto_renewal,
          bmev: budget.bmev?.toString() || '',
          bmevThreshold: budget.bmev_alert_threshold,
          btrt: budget.btrt?.toString() || '',
          btrtThreshold: budget.btrt_alert_threshold
        };

        setBudgetData(formattedBudget);
      } catch (error) {
        console.error('Error fetching budget:', error);
        setError('Failed to load budget. Please try again.');
        toast.error('Failed to load budget data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBudget();
  }, [budgetId]);

  const handleFormSubmit = async (data: any) => {
    if (!budgetId) return;

    try {
      setIsSubmitting(true);

      // Convert HSL color to hex
      const colorHex = hslToHex(data.budgetColor);

      // Transform form data to match API expectations
      const updatedBudgetData = {
        name: data.budgetName,
        type: data.budgetType,
        icon_id: data.budgetIcon,
        color_hex: colorHex,
        description: data.description || '',
        start_date: data.startDate.toISOString().split('T')[0],
        end_date: data.endDate.toISOString().split('T')[0],
        period: data.period,
        currency: data.currency,
        is_auto_renewal: data.autoRenewal,
        bmev: typeof data.bmev === 'string' && data.bmev ? Number(data.bmev) : 0,
        bmev_alert_threshold: data.bmevThreshold,
        btrt: typeof data.btrt === 'string' && data.btrt ? Number(data.btrt) : 0,
        btrt_alert_threshold: data.btrtThreshold
      };

      await budgetService.updateBudget(budgetId, updatedBudgetData);

      toast.success('Budget updated successfully!');
      navigate('/budgets'); // Redirect to budgets listing page
    } catch (error) {
      console.error('Error updating budget:', error);
      toast.error('Failed to update budget. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleButtonClick = () => {
    const form = document.getElementById('budget-creation-form');
    if (form) {
      form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    } else {
      console.error('Form element not found');
    }
  };

  const handleCancel = () => {
    navigate('/budgets');
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        {/* <Spinner size="lg" /> */}
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <p className="text-red-500 mb-4">{error}</p>
          <Button onClick={() => navigate('/budgets')}>Return to Budgets</Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <MobileAppBar title="Edit Budget" onRightIconClick={() => {}} rightIcon={gearIcon} />

      <div className="bg-[#FBFBFF] p-4 md:bg-white">
        <h2 className="text-center font-bold mb-5 md:text-left md:text-[24px] md:mb-6">EDIT YOUR BUDGET</h2>

        {/* BUDGET CREATION FORM WITH INITIAL VALUES */}
        {budgetData && <BudgetCreationForm onSubmit={handleFormSubmit} initialValues={budgetData} />}
      </div>

      {/* FOOTER */}
      <footer className="px-4 py-7 bg-gradient-to-b from-[#F0EFFD]/0 from-0% to-[#F0EFFD] to-[17.74%] md:bg-white md:bg-none md:from-transparent md:to-transparent md:flex md:justify-end">
        <Button
          className="hidden md:block rounded-[24px] text-base font-semibold px-6 h-[44px] text-[16px] w-full md:w-fit text-[#E8507E]"
          variant={'text'}
          onClick={handleCancel}
          disabled={isSubmitting}
          type="button"
        >
          CANCEL
        </Button>
        <Button
          className="rounded-[24px] text-base font-semibold px-6 h-[44px] text-[16px] w-full md:w-fit"
          onClick={handleButtonClick}
          disabled={isSubmitting}
          type="button"
        >
          {isSubmitting ? 'SAVING...' : 'SAVE CHANGES'}
        </Button>
      </footer>
    </>
  );
};

export default EditPage;
