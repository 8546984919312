import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import DashboardTemplate from '@/components/templates/DashboardTemplate';
import { useDeviceType } from '@/hooks/useDeviceType';
import SidebarLink from '@/components/molecules/SidebarLink';
import { useState, useEffect } from 'react';
import { PAGE } from '@/routes';

const ProfileLayout = () => {
  const [activeLink, setActiveLink] = useState('PROFILE');
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobileView } = useDeviceType();

  // Update active link based on current route
  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/profile/subscription')) {
      setActiveLink('SUBSCRIPTION');
    } else if (path.includes('/profile/terminology')) {
      setActiveLink('TERMINOLOGY');
    } else if (path.includes('/profile')) {
      setActiveLink('PROFILE');
    }
  }, [location.pathname]);

  const handleNavigation = (path: string) => {
    setActiveLink(path);
    navigate(PAGE.PROFILE.children[path]);
  };

  return (
    <DashboardTemplate>
      {isMobileView ? (
        <Outlet />
      ) : (
        <div className="h-full flex gap-6">
          <aside className="bg-white h-fit rounded-[16px] w-[250px] overflow-hidden pr-[2px]">
            <SidebarLink
              label="Profile"
              iconKey="profile"
              isActive={activeLink === 'PROFILE'}
              onClick={() => handleNavigation('PROFILE')}
            />
            <SidebarLink
              label="Subscription"
              iconKey="subscription"
              isActive={activeLink === 'SUBSCRIPTION'}
              onClick={() => handleNavigation('SUBSCRIPTION')}
            />
            <SidebarLink
              label="Terminology"
              iconKey="terminology"
              isActive={activeLink === 'TERMINOLOGY'}
              onClick={() => handleNavigation('TERMINOLOGY')}
            />
          </aside>
          <div className="bg-white rounded-[16px] p-4 px-5 flex-1 border overflow-y-auto">
            <Outlet />
          </div>
        </div>
      )}
    </DashboardTemplate>
  );
};

export default ProfileLayout;
