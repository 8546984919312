import SidebarLink from '@/components/molecules/SidebarLink';
import React from 'react';
import Logo from '@/assets/images/logo512.png';
import { useNavigate, useLocation } from 'react-router-dom';

const WebSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Get the current path without the leading slash
  const currentPath = location.pathname.slice(1);

  const handleNavigation = (path: string) => {
    navigate(`/${path}`);
  };

  return (
    <aside className="w-[20%] max-w-[260px] min-w-[260px] pt-[3rem] bg-white shadow-[2px_0px_4px_0px_#00000040]">
      <img className="mx-auto w-[80px] h-[77px]" src={Logo} alt="Batjet Logo" />
      <section className="mt-10">
        <SidebarLink
          label="Dashboard"
          iconKey="dashboard"
          isActive={currentPath === 'dashboard'}
          onClick={() => handleNavigation('dashboard')}
        />
        <SidebarLink
          label="Budget"
          iconKey="budget"
          isActive={currentPath === 'budgets'}
          onClick={() => handleNavigation('budgets')}
        />
        <SidebarLink
          label="Invoice"
          iconKey="invoice"
          isActive={currentPath === 'invoice'}
          onClick={() => handleNavigation('invoice')}
        />
        <SidebarLink
          label="Commitment"
          iconKey="commitment"
          isActive={currentPath === 'commitment'}
          onClick={() => handleNavigation('commitment')}
        />
      </section>
    </aside>
  );
};

export default WebSidebar;
