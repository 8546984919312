import { apiService } from '@/api/apiConfig';
import {
  BudgetCreationData,
  BudgetCreationResponse,
  BudgetUpdateData,
  BudgetUpdateResponse,
  BudgetResponse,
  BudgetsResponse
} from '@/services/types/budgets.types';

const handleBudgetError = (error: any): Error => {
  // Check if the error has a response with data
  if (error.response?.data?.message) {
    return new Error(`Budget operation failed: ${error.response.data.message}`);
  }
  return error instanceof Error ? error : new Error('Budget operation failed');
};

export const budgetService = {
  createBudget: async (budgetData: BudgetCreationData): Promise<BudgetCreationResponse> => {
    try {
      return await apiService.request<BudgetCreationResponse>({
        method: 'POST',
        url: '/budget',
        data: budgetData,
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      throw handleBudgetError(error);
    }
  },

  getBudget: async (budgetId: string): Promise<BudgetResponse> => {
    try {
      return await apiService.request<BudgetResponse>({
        method: 'GET',
        url: `/budget/${budgetId}`
      });
    } catch (error) {
      throw handleBudgetError(error);
    }
  },

  getBudgets: async (): Promise<BudgetsResponse> => {
    try {
      return await apiService.request<BudgetsResponse>({
        method: 'GET',
        url: '/budget'
      });
    } catch (error) {
      throw handleBudgetError(error);
    }
  },

  updateBudget: async (budgetId: string, budgetData: BudgetUpdateData): Promise<BudgetUpdateResponse> => {
    try {
      return await apiService.request<BudgetUpdateResponse>({
        method: 'PATCH',
        url: `/budget/${budgetId}`,
        data: budgetData,
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      throw handleBudgetError(error);
    }
  },

  deleteBudget: async (budgetId: string): Promise<void> => {
    try {
      return await apiService.request<void>({
        method: 'DELETE',
        url: `/budget/${budgetId}`
      });
    } catch (error) {
      throw handleBudgetError(error);
    }
  }
};
