import React, { useEffect, useState } from 'react';
import BudgetCarousel from '@/components/molecules/BudgetCarousel';
import { Card, CardContent } from '@/components/ui/card';
import budgetsIcon from '@/assets/icons/budgets.svg';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { formatDate, getCurrencyInfo } from '@/utils/helpers';
import arrowRightIcon from '@/assets/icons/arrow-right.svg';
import { budgetService } from '@/services/budget.service';

interface Budget {
  id: string;
  title: string;
  company: string;
  btw: string;
  remainingBtw: string;
  color: string;
  description?: string;
  startingThreshold?: string;
  endingThreshold?: string;
  startDate?: string;
  endDate?: string;
  name?: string;
  type?: string;
  icon_id?: string;
  color_hex?: string;
  currency?: string;
  bmevAlertThreshold?: number;
  btrtAlertThreshold?: number;
  isAutoRenewal?: boolean;
}

interface BudgetSectionProps {
  // budgets: Budget[];
}

export const BudgetSection: React.FC<BudgetSectionProps> = () => {
  const [budgets, setBudgets] = useState<any[]>([]);
  const [selectedBudget, setSelectedBudget] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  const fetchBudgets = async () => {
    try {
      const { data: budgetsData } = await budgetService.getBudgets();

      setSelectedBudget(budgetsData[0]);
      setBudgets(budgetsData);
    } catch (error) {
      console.error('Error fetching budgets:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  const handleBudgetSelect = (budget: any) => {
    setSelectedBudget(budget);
  };

  const handleDetailClick = () => {
    navigate(`/budgets/${selectedBudget?._id}`);
  };

  if (loading) {
    return <div className="flex items-center justify-center -mt-8 h-full">Loading...</div>;
  }

  return (
    <div className="space-y-8 p-0">
      <div className="bg-white p-5 overflow-hidden rounded-[16px] border">
        <header className="flex items-start justify-between mb-[1rem]">
          <h2 className="text-2xl font-semibold flex items-center gap-2">
            {' '}
            <img src={budgetsIcon} alt="" /> My Budget
          </h2>
          <Button
            className="hidden md:block rounded-[24px] text-base font-semibold px-6 py-0 h-[38px] text-[16px] w-full md:w-fit text-primary"
            variant={'text'}
            onClick={() => {
              navigate('/budgets/add');
            }}
            // disabled={isSubmitting}
            type="button"
          >
            + Create a Budget
          </Button>
        </header>
        <BudgetCarousel budgets={budgets} onBudgetSelect={handleBudgetSelect} />
      </div>

      {selectedBudget && (
        <Card className="w-full mx-auto rounded-[16px] border shadow-none">
          <CardContent className="p-5">
            <h2 className="text-2xl font-semibold mb-4 border-b pb-[1.1rem]">Budget Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div className="col-span-full">
                <p className="text-sm text-lightPurple-300">Description</p>
                <p className={`font-medium text-base text-gray ${isExpanded ? '' : 'line-clamp-2'}`}>
                  {selectedBudget.description}
                </p>
                {!isExpanded && selectedBudget.description && selectedBudget.description.length > 40 && (
                  <Button variant={'text'} className="h-fit p-0 m-0 text-sm" onClick={() => setIsExpanded(true)}>
                    See More
                  </Button>
                )}
                {isExpanded && (
                  <Button variant={'text'} className="h-fit p-0 m-0 text-sm" onClick={() => setIsExpanded(false)}>
                    See Less
                  </Button>
                )}
              </div>
              <div>
                <p className="text-sm text-lightPurple-300">Alerting threshold of BMEV</p>
                <p className="font-medium text-base text-gray">{selectedBudget?.bmev_alert_threshold || '-'}</p>
              </div>
              <div>
                <p className="text-sm text-lightPurple-300">Alerting threshold of BTRT</p>
                <p className="font-medium text-base text-gray">{selectedBudget?.btrt_alert_threshold || '-'}</p>
              </div>
              <div>
                <p className="text-sm text-lightPurple-300">Default Currency Type</p>
                <p className="font-medium text-base text-gray">{getCurrencyInfo(selectedBudget.currency || '')}</p>
              </div>
              <div>
                <p className="text-sm text-lightPurple-300">Automatic Renewal</p>
                <p className="font-medium text-base text-gray">{selectedBudget.isAutoRenewal ? 'On' : 'Off'}</p>
              </div>
              <div>
                <p className="text-sm text-lightPurple-300">Budget starting date</p>
                <p className="font-medium text-base text-gray">{formatDate(selectedBudget.start_date || '')}</p>
              </div>
              <div>
                <p className="text-sm text-lightPurple-300">Budget ending date</p>
                <p className="font-medium text-base text-gray">{formatDate(selectedBudget.end_date || '')}</p>
              </div>
              <div className="col-span-full mt-6">
                <Button className="w-full text-sm rounded-full h-auto" onClick={handleDetailClick}>
                  SEE BUDGET DETAIL
                  <img className="ml-2" src={arrowRightIcon} alt="" />
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
