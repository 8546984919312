import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import MobileAppBar from '@/components/molecules/MobileAppBar';
import BudgetCreationForm from '@/components/organisms/BudgetCreationForm';
import { Button } from '@/components/ui/button';
import gearIcon from '@/assets/icons/gear.svg';
import { budgetService } from '@/services/budget.service';
import { hslToHex } from '@/utils/ColorConverter';

const AddPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = async (data: any) => {
    try {
      setIsSubmitting(true);

      // Convert HSL color to hex
      const colorHex = hslToHex(data.budgetColor);

      // Transform form data to match API expectations
      const budgetData = {
        name: data.budgetName,
        type: data.budgetType,
        icon_id: data.budgetIcon,
        color_hex: colorHex, // Use converted hex color
        description: data.description || '',
        start_date: data.startDate.toISOString().split('T')[0],
        end_date: data.endDate.toISOString().split('T')[0],
        period: data.period,
        currency: data.currency,
        is_auto_renewal: data.autoRenewal,
        bmev: typeof data.bmev === 'string' && data.bmev ? Number(data.bmev) : 0,
        bmev_alert_threshold: data.bmevThreshold,
        btrt: typeof data.btrt === 'string' && data.btrt ? Number(data.btrt) : 0,
        btrt_alert_threshold: data.btrtThreshold
      };

      const response: any = await budgetService.createBudget(budgetData);

      toast.success('Budget created successfully!');
      navigate('/budgets/' + response?.data._id); // Redirect to budgets listing page
    } catch (error) {
      console.error('Error creating budget:', error);
      toast.error('Failed to create budget. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleButtonClick = () => {
    console.log('Create button clicked');
    const form = document.getElementById('budget-creation-form');
    if (form) {
      console.log('Form found, dispatching submit event');
      form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    } else {
      console.error('Form element not found');
    }
  };

  const handleCancel = () => {
    navigate('/budgets');
  };

  return (
    <>
      <MobileAppBar title="Budget Creation" onRightIconClick={() => {}} rightIcon={gearIcon} />

      <div className="bg-[#FBFBFF] p-4 md:bg-white">
        <h2 className="text-center font-bold mb-5 md:text-left md:text-[24px] md:mb-6">CREATE YOUR BUDGET</h2>

        {/* BUDGET CREATION FORM */}
        <BudgetCreationForm onSubmit={handleFormSubmit} />
      </div>

      {/* FOOTER */}
      <footer className="px-4 py-7 bg-gradient-to-b from-[#F0EFFD]/0 from-0% to-[#F0EFFD] to-[17.74%] md:bg-white md:bg-none md:from-transparent md:to-transparent md:flex md:justify-end">
        <Button
          className="hidden md:block rounded-[24px] text-base font-semibold px-6 h-[44px] text-[16px] w-full md:w-fit text-[#E8507E]"
          variant={'text'}
          onClick={handleCancel}
          disabled={isSubmitting}
          type="button"
        >
          CANCEL
        </Button>
        <Button
          className="rounded-[24px] text-base font-semibold px-6 h-[44px] text-[16px] w-full md:w-fit"
          onClick={handleButtonClick}
          disabled={isSubmitting}
          type="button"
        >
          {isSubmitting ? 'CREATING...' : 'CREATE'}
        </Button>
      </footer>
    </>
  );
};

export default AddPage;
