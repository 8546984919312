import { auth } from '@/firebase.config';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import Cookies from 'js-cookie';
import { setAuthPersistence } from '@/firebase.config';

import { apiService } from '../api/apiConfig';
import { store } from '../store';
import { setUser } from '../store/slices/auth';
import { AuthResponse, SignupCredentials } from './types/auth.types';

const handleAuthSuccess = (response: AuthResponse): void => {
  // Cookies.set('jwtToken', response.token);
  // Cookies.set('refreshToken', response.refreshToken);
  // store.dispatch(setUser(response.user));
};

const handleAuthError = (error: any): Error => {
  clearAuth();
  return error instanceof Error ? error : new Error('Authentication failed');
};

const clearAuth = (): void => {
  Cookies.remove('firebaseToken');
  Cookies.remove('refreshToken');
  store.dispatch(setUser(null));
};

export const setUserInStore = (userToJson: any): void => {
  const { displayName, email, phoneNumber, photoURL, uid } = userToJson;

  // Handle case where displayName might be null
  let firstName = '';
  let lastName = '';

  if (displayName) {
    const nameParts = displayName.split(' ');
    firstName = nameParts[0];
    lastName = nameParts.slice(1).join(' ');
  }

  // store user in Redux
  store.dispatch(
    setUser({
      firstName,
      lastName,
      name: displayName || email,
      email,
      phoneNumber,
      photoURL,
      id: uid
    })
  );
};

export const authService = {
  signup: async (credentials: SignupCredentials): Promise<AuthResponse> => {
    try {
      // sign-up api call
      const response = await apiService.request<AuthResponse>({
        method: 'POST',
        url: '/auth/sign-up',
        data: credentials
      });

      // sign in with Firebase
      const firebaseAuth = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);

      // get Firebase token
      const firebaseToken = await firebaseAuth.user.getIdToken();

      // store tokens and user data
      Cookies.set('firebaseToken', firebaseToken);

      handleAuthSuccess(response);
      return response;
    } catch (error) {
      throw handleAuthError(error);
    }
  },

  login: async (credentials: { email: string; password: string }, rememberMe = false): Promise<void> => {
    try {
      store.dispatch(setUser(null));
      // Set the appropriate persistence based on rememberMe flag
      await setAuthPersistence(rememberMe);

      // sign in with Firebase
      const firebaseAuth = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);

      // get Firebase token
      const firebaseToken = await firebaseAuth.user.getIdToken();

      const userToJson: any = await firebaseAuth.user.toJSON();

      const { data: profileData } = await apiService.request<any>({
        method: 'GET',
        url: '/profile/firebase/' + userToJson?.uid
      });

      store.dispatch(
        setUser({
          firstName: profileData?.firstName,
          lastName: profileData?.lastName,
          name: profileData?.firstName + ' ' + (profileData?.lastName || ''),
          email: profileData?.email,
          phoneNumber: profileData?.phoneNumber,
          photoURL: profileData?.profilePicUrl,
          id: profileData?._id,
          fid: profileData?.firebaseUid,
          username: profileData?.username
        })
      );

      // store token in cookies if you want (though Firebase handles persistence already)
      Cookies.set('firebaseToken', firebaseToken);

      // Optionally store the rememberMe preference
      if (rememberMe) {
        localStorage.setItem('rememberMe', 'true');
      } else {
        localStorage.removeItem('rememberMe');
      }
    } catch (error) {
      throw handleAuthError(error);
    }
  },

  logout: async (): Promise<void> => {
    try {
      // signing out from Firebase
      await signOut(auth);

      // clearing cookies and local state
      clearAuth();

      // Remove any rememberMe preference
      localStorage.removeItem('rememberMe');

      // try {
      //   await apiService.request({
      //     method: 'POST',
      //     url: '/auth/logout'
      //   });
      // } catch (error) {
      //   console.error('Backend logout failed:', error);
      // }
    } catch (error) {
      throw new Error('Logout failed');
    }
  },

  refreshToken: async (refreshToken: string): Promise<void> => {
    try {
      const response = await apiService.request<{ token: string; refreshToken: string }>({
        method: 'POST',
        url: '/auth/refresh-token',
        data: { refreshToken }
      });

      Cookies.set('jwtToken', response.token);
      Cookies.set('refreshToken', response.refreshToken);
    } catch (error) {
      clearAuth();
      throw error;
    }
  },

  isAuthenticated: (): boolean => {
    return !!Cookies.get('firebaseToken') || !!auth.currentUser;
  },

  // Method to check if the user is already authenticated through Firebase persistence
  getCurrentUser: () => {
    return auth.currentUser;
  }
};
