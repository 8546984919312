'use client';

import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker, CaptionProps, useNavigation } from 'react-day-picker';
// import 'react-day-picker/style.css';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { format } from 'date-fns';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function CustomCaptionComponent(props: CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  return (
    <div className="w-full flex items-center justify-between">
      <button
        className="flex items-center text-base font-semibold"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <ChevronLeft className="text-primary h-6 w-6 opacity-100" />
        {previousMonth ? format(previousMonth, 'MMM') : ''}
      </button>
      <span className="text-base font-bold leading-[19.5px]">{format(props.displayMonth, 'MMM yyy')}</span>
      <button
        className="flex items-center text-base font-semibold"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        {nextMonth ? format(nextMonth, 'MMM') : ''}
        <ChevronRight className="text-primary h-6 w-6 opacity-100" />
      </button>
    </div>
  );
}

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      fixedWeeks={true}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4 w-full max-w-[350px] md:min-w-[350px]',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md flex-1 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: cn(
          'relative text-lg flex-1 p-0 text-center focus-within:relative focus-within:z-20 [&:has([aria-selected].day-range-end)]:rounded-r-md',
          props.mode === 'range'
            ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
            : '[&:has([aria-selected])]:rounded-md'
        ),
        day: 'h-[40px] w-[33px] p-0 font-normal aria-selected:opacity-100',
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_selected: 'bg-transparent border border-primary text-primary rounded-full',
        day_today: 'bg-accent text-accent-foreground',
        day_outside: 'day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames
      }}
      components={{
        Caption: CustomCaptionComponent
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
