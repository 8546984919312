import MobileAppBar from '@/components/molecules/MobileAppBar';
import ProfileCreationForm from '@/components/organisms/ProfileCreationForm';
import { getCurrentUser } from '@/store/slices/auth';
import { useSelector } from 'react-redux';

const EditProfilePage = () => {
  const currentUser = useSelector(getCurrentUser);

  return (
    <div className="pb-5">
      <MobileAppBar title="Edit Profile" />
      <ProfileCreationForm variant="edit" size="sm" profile={currentUser} />
    </div>
  );
};

export default EditProfilePage;
