import DashboardTemplate from '@/components/templates/DashboardTemplate';
import React from 'react';

const BankAccountOverviewPage = () => {
  return (
    <DashboardTemplate>
      <div>BankAccountOverviewPage</div>
    </DashboardTemplate>
  );
};

export default BankAccountOverviewPage;
