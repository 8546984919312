import { cn } from '@/lib/utils';
import { formatNumberToK } from '@/utils/helpers';
import chevronIcon from '@/assets/icons/chevron-primary.svg';

interface ListItem {
  id: string;
  title: string;
  icon: string;
  data: { subtitle: string; value: string }[];
  accountName: string;
}

interface OverviewListProps<T extends ListItem> {
  title: string;
  items?: T[];
  icon: string;
  addButtonIcon: string;
  addButtonTitle: string;
  valuePrefix?: string; // e.g., "€", "Remaining", etc.
  valueSuffix?: string;
  onAddClick: () => void;
  onSeeAllClick?: () => void;
  className?: string;
  limit?: number;
  accountName?: string;
}

const OverviewList = <T extends ListItem>({
  title,
  items = [],
  icon,
  addButtonIcon,
  addButtonTitle,
  valuePrefix = '',
  valueSuffix = '',
  onAddClick,
  onSeeAllClick,
  className,
  limit = 2
}: OverviewListProps<T>) => {
  const showSeeAll = items.length >= 3;

  return (
    <div className={cn('', className)}>
      <div className="p-4 px-2 md:px-4 pb-2 md:pb-4">
        <div className="flex justify-between mb-[0.85rem]">
          <p className="pl-2 text-xs text-lightPurple-300 font-medium md:text-foreground md:font-bold md:text-base">
            {title}
          </p>
          {showSeeAll && (
            <button onClick={onSeeAllClick} className="text-primary text-xs font-medium">
              See All
            </button>
          )}
        </div>

        {items.length > 0 && (
          <div className="border border-white rounded-[8px] space-y-0 px-4 md:px-0 md:space-y-4">
            {items.slice(0, limit).map((item) => (
              <button
                key={item.id}
                onClick={onAddClick}
                className="flex items-center justify-start gap-3 w-full p-0 py-[0.85rem] border-b border-white last:border-b-0 md:border md:border-lightPurple md:px-4 md:last:border md:last:border-lightPurple md:rounded-[8px]"
              >
                <img src={icon} alt={`${title} icon`} />
                <div className="flex flex-1 flex-col gap-[2px] w-[72%]">
                  <p className="text-sm font-bold leading-[17px] text-left truncate capitalize">{item?.title}</p>
                  <div className="flex flex-col">
                    {item.data &&
                      item.data?.map((itemData: any, index: any) => (
                        <span key={index} className="text-left text-xs text-secondary font-medium">
                          {itemData.subtitle +
                            ' ' +
                            `${valuePrefix}${valueSuffix === 'k' ? formatNumberToK(itemData.value) : itemData.value + ' ' + valueSuffix}`}
                        </span>
                      ))}
                  </div>
                </div>
                <span className="flex items-center justify-center min-w-[26px] min-h-[26px] rounded-full bg-lightPurple-200">
                  <img className="rotate-90  scale-x-[1.2]" src={chevronIcon} alt={`chevron icon`} />
                </span>
              </button>
            ))}
          </div>
        )}

        <button
          onClick={onAddClick}
          className="mt-[8px] flex items-center justify-between gap-3 border rounded-[8px] w-full border-white md:border-lightPurple p-4 py-3"
        >
          <div className="flex items-center gap-2">
            <img src={icon} alt={`${title} icon`} />
            <p className="uppercase text-sm font-semibold leading-[17px] text-left">{addButtonTitle}</p>
          </div>
          <span className="min-w-[26px] min-h-[26px] bg-lightPurple-200 rounded-full flex items-center justify-center">
            <img src={addButtonIcon} alt="add icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export type { ListItem, OverviewListProps };
export default OverviewList;
