import React, { useState, useEffect } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import SwitchField from '@/components/atoms/SwitchField';
import calendarIcon from '@/assets/icons/calender-2.svg';
import chevronPrimaryRoundedIcon from '@/assets/icons/chevron-rounded-primary-down.svg';
import { getCurrencyInfo } from '@/utils/helpers';

interface BudgetGeneralInfoAccordionProps {
  description?: string;
  currencyType?: string;
  bmev?: number;
  bmevThreshold?: number;
  btrt?: number;
  btrtThreshold?: number;
  autoRenewal?: boolean;
  startDate?: string;
  endDate?: string;
  currency?: string;
  onAutoRenewalChange?: (checked: boolean) => void;
}

const BudgetGeneralInfoAccordion: React.FC<BudgetGeneralInfoAccordionProps> = ({
  description = 'Budget allocation for nuwbreeding company this year',
  currency = 'EUR',
  bmev = 100000,
  bmevThreshold = 80,
  btrt = 500000,
  btrtThreshold = 80,
  autoRenewal = true,
  startDate = '01/01/2024',
  endDate = '12/31/2024',
  onAutoRenewalChange
}) => {
  const [isAutoRenewal, setIsAutoRenewal] = useState(autoRenewal);

  useEffect(() => {
    setIsAutoRenewal(autoRenewal);
  }, [autoRenewal]);

  const handleAutoRenewalChange = (checked: boolean) => {
    setIsAutoRenewal(checked);
    if (onAutoRenewalChange) {
      onAutoRenewalChange(checked);
    }
  };

  const currencySymbol = currency === 'EUR' ? '€' : '$';

  return (
    <Accordion defaultValue="item-1" type="single" collapsible className="mt-8">
      <AccordionItem value="item-1" className="bg-lightPurple rounded-[16px] overflow-hidden">
        <AccordionTrigger
          icon={<img src={chevronPrimaryRoundedIcon} alt="chevron icon" />}
          className="border-b border-lightPurple-200 py-0 min-h-[52px] px-4"
        >
          <div>
            <p className="text-primary font-bold text-base">General Information</p>
          </div>
        </AccordionTrigger>
        <AccordionContent className="p-4 space-y-[1.1rem]">
          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Description</span>
            <p className="text-sm font-medium leading-[17px]">{description}</p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Default Currency Type</span>
            <p className="text-sm font-medium leading-[17px]">{getCurrencyInfo(currency)}</p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Budget maximum expense value (BMEV)</span>
            <p className="text-sm font-medium leading-[17px]">
              {currencySymbol}
              {bmev.toLocaleString()}
            </p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Alerting threshold of BMEV</span>
            <p className="text-sm font-medium leading-[17px]">{bmevThreshold}%</p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Budget total revenue target (BTRT)</span>
            <p className="text-sm font-medium leading-[17px]">
              {currencySymbol}
              {btrt.toLocaleString()}
            </p>
          </div>

          <div>
            <span className="text-[11px] text-lightPurple-300 font-medium">Alerting threshold of BTRT</span>
            <p className="text-sm font-medium leading-[17px]">{btrtThreshold}%</p>
          </div>

          <div>
            <SwitchField
              name="autoRenewal"
              label="Automatic Renewal"
              checked={isAutoRenewal}
              onCheckedChange={handleAutoRenewalChange}
              className="flex-1"
            />
            {isAutoRenewal && (
              <div className="bg-lightPurple-200 rounded-[12px] p-2 px-[0.625rem] mt-[1.1rem] flex items-center gap-3">
                <img src={calendarIcon} alt="calendar icon" />
                <p className="text-sm font-bold">
                  {startDate} - {endDate}
                </p>
              </div>
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default BudgetGeneralInfoAccordion;
