// utils/ColorConverter.ts

/**
 * Converts HSL color string to hex color string
 * Handles format "hsl(h, s%, l%)" with various spacing patterns
 * @param hsl - HSL color string
 * @returns Hex color string
 */
export function hslToHex(hsl: string): string {
  try {
    // Handle different HSL formats with flexible spacing
    const match = hsl.match(/hsl\(\s*(\d+)\s*,\s*(\d+)%\s*,\s*(\d+)%\s*\)/);
    if (!match) {
      // If input is already a hex color, return as is
      if (hsl.startsWith('#')) {
        return hsl;
      }
      console.warn('Invalid color format:', hsl);
      return '#000000';
    }

    // Parse the h, s, l values
    const h = parseInt(match[1], 10) / 360;
    const s = parseInt(match[2], 10) / 100;
    const l = parseInt(match[3], 10) / 100;

    // Convert HSL to RGB
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    // Convert RGB to hex
    const toHex = (x: number) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  } catch (error) {
    console.error('Error converting color:', error);
    return '#000000'; // Return black as fallback
  }
}

/**
 * Convert HEX color to HSL format
 * Input: "#RRGGBB" or "RRGGBB"
 * Output: "hsl(h, s%, l%)"
 */
export const hexToHsl = (hexString: string): string => {
  try {
    // Ensure the hex string has a hash prefix and is valid
    let hex = hexString;
    if (!hex.startsWith('#')) {
      hex = '#' + hex;
    }

    // Convert hex to RGB
    let r = 0,
      g = 0,
      b = 0;
    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex.substr(1, 2), 16);
      g = parseInt(hex.substr(3, 2), 16);
      b = parseInt(hex.substr(5, 2), 16);
    }
    // Error case - return a default HSL
    else {
      console.error('Invalid hex color format:', hexString);
      return 'hsl(0, 100%, 50%)'; // Default to red
    }

    // Convert RGB to HSL
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0,
      s = 0,
      l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    return `hsl(${h}, ${s}%, ${l}%)`;
  } catch (error) {
    console.error('Error converting Hex to HSL:', error);
    return 'hsl(0, 100%, 50%)'; // Default to red
  }
};
