import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { auth } from '@/firebase.config';
import { errorMessages } from '@/store/errorMessages';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

const AUTH_ROUTES = ['/auth/sign-up'];

const createApiService = () => {
  const api: AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
      Connection: 'keep-alive'
    },
    timeout: 10000
  });

  const handleRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    // check if the current request URL is an auth route
    const isAuthRoute = AUTH_ROUTES.some((route) => config.url?.includes(route));
    console.log(isAuthRoute);

    //only add Authorization header if it's not an auth route
    if (!isAuthRoute) {
      if (auth.currentUser) {
        // getIdToken automatically refresh the token, if token is expired or about to expire
        const token = await auth.currentUser.getIdToken(false);
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  };

  const handleRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error('Request error:', error);
    return Promise.reject(error);
  };

  const handleResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  const handleResponseError = async (error: AxiosError): Promise<AxiosError> => {
    const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken();
        return api(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  };

  const refreshToken = async (): Promise<void> => {
    try {
      const refreshToken = Cookies.get('refreshToken');
      const response = await api.post('/auth/refresh-token', { refreshToken });
      const { token, refreshToken: newRefreshToken } = response.data;
      Cookies.set('jwtToken', token);
      Cookies.set('refreshToken', newRefreshToken);
    } catch (error) {
      Cookies.remove('jwtToken');
      Cookies.remove('refreshToken');
      throw error;
    }
  };

  const handleError = (error: AxiosError | any): Error => {
    console.log('error:', error);
    if (error.response) {
      let errMessage =
        errorMessages[error?.response?.data?.error as keyof typeof errorMessages] ||
        error?.response?.data?.error ||
        'An error occurred! Please try again';

      toast.error(errMessage);
      return new Error(errMessage);
    } else if (error.request) {
      toast.error('Timeout, Server is down!');

      return new Error('No response received from the server');
    } else {
      toast.error('An error occurred while setting up the request');
      return new Error('An error occurred while setting up the request');
    }
  };

  api.interceptors.request.use(handleRequest, handleRequestError);
  api.interceptors.response.use(handleResponse, handleResponseError);

  const request = async <T>(config: AxiosRequestConfig): Promise<T> => {
    try {
      const response = await api.request<T>(config);
      return response.data;
    } catch (error) {
      throw handleError(error as AxiosError);
    }
  };

  return { request };
};

export const apiService = createApiService();
