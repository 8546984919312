export const MOBILE_ICON_POSITIONS = {
  dashboard: {
    inactive: '0 0',
    active: '0 -37px'
  },
  budget: {
    inactive: '-38px 0',
    active: '-38px -37px'
  },
  invoice: {
    inactive: '-76px 0',
    active: '-76px -37px'
  },
  commitment: {
    inactive: '-114px 0',
    active: '-114px -37px'
  },
  profile: {
    inactive: '-152px 0',
    active: '-152px -37px'
  }
};

export const WEB_ICON_POSITIONS = {
  dashboard: {
    inactive: '0 0',
    active: '0 -25px'
  },
  commitment: {
    inactive: '-26px 0',
    active: '-26px -25px'
  },
  invoice: {
    inactive: '-52px 0',
    active: '-52px -25px'
  },
  budget: {
    inactive: '-78px 0',
    active: '-78px -25px'
  },
  terminology: {
    inactive: '-105px 0',
    active: '-105px -25px'
  },
  subscription: {
    inactive: '-132px 0',
    active: '-132px -25px'
  },
  profile: {
    inactive: '-158px 0',
    active: '-158px -25px'
  }
};
