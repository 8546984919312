import { apiService } from '@/api/apiConfig';
import {
  ProfileCreationData,
  ProfileCreationResponse,
  ProfileUpdateData,
  ProfileUpdateResponse
} from '@/services/types/user.types';

const handleUserError = (error: any): Error => {
  return error instanceof Error ? error : new Error('User operation failed');
};

export const userService = {
  createProfile: async (profileData: ProfileCreationData): Promise<ProfileCreationResponse> => {
    try {
      // if there's a profile picture, handle it with FormData
      if (profileData.profilePicture) {
        const formData = new FormData();
        formData.append('profilePicture', profileData.profilePicture);

        (Object.keys(profileData) as Array<keyof ProfileCreationData>).forEach((key) => {
          if (key !== 'profilePicture' && profileData[key]) {
            formData.append(key, String(profileData[key]));
          }
        });

        return await apiService.request<ProfileCreationResponse>({
          method: 'POST',
          url: '/auth/create-profile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      // if no profile picture, send JSON data
      return await apiService.request<ProfileCreationResponse>({
        method: 'POST',
        url: '/auth/create-profile',
        data: profileData
      });
    } catch (error) {
      throw handleUserError(error);
    }
  },

  updateProfile: async (profileData: ProfileUpdateData): Promise<ProfileUpdateResponse> => {
    try {
      if (profileData.profilePicture) {
        const formData = new FormData();
        formData.append('profilePicture', profileData.profilePicture);

        (Object.keys(profileData) as Array<keyof ProfileUpdateData>).forEach((key) => {
          if (key !== 'profilePicture' && profileData[key]) {
            formData.append(key, String(profileData[key]));
          }
        });

        return await apiService.request<ProfileUpdateResponse>({
          method: 'POST',
          url: '/profile/edit-profile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      return await apiService.request<ProfileUpdateResponse>({
        method: 'POST',
        url: '/profile/edit-profile',
        data: profileData
      });
    } catch (error) {
      throw handleUserError(error);
    }
  },

  deleteProfile: async (firebaseId: string): Promise<void> => {
    try {
      return await apiService.request<void>({
        method: 'DELETE',
        url: `/profile/${firebaseId}`
      });
    } catch (error) {
      throw handleUserError(error);
    }
  }
};
