import MobileAppBar from '@/components/molecules/MobileAppBar';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import binIcon from '@/assets/icons/bin.svg';
import budgetIcon from '@/assets/icons/budget.svg';
import editIcon from '@/assets/icons/edit.svg';
import percentageIcon from '@/assets/icons/percentage.svg';
import euroIcon from '@/assets/icons/euro.svg';
import vaultIcon from '@/assets/icons/vault.svg';
import plusIcon from '@/assets/icons/plus.svg';
import buildingIcon from '@/assets/icons/building.svg';
import upAndDownArrowIcon from '@/assets/icons/upAndDownArrow.svg';
import departmentAcsIcon from '@/assets/icons/department-acs.svg';
import productAndServicesIcon from '@/assets/icons/product-and-services.svg';
import projectAvsIcon from '@/assets/icons/project-acs.svg';
import expenseIcon from '@/assets/icons/expense.svg';
import revenueIcon from '@/assets/icons/revenue.svg';
import noInvoiceIcon from '@/assets/icons/no-invoice.svg';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ChartConfig } from '@/components/ui/chart';
import PieGraph from '@/components/organisms/graphs/PieGraph';
import BudgetGeneralInfoAccordion from '@/components/molecules/BudgetGeneralInfoAccordion';
import OverviewList, { ListItem } from '@/components/organisms/OverviewList';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { useDeviceType } from '@/hooks/useDeviceType';
import BudgetInfoCard from '@/components/molecules/BudgetInfoCard';
import { budgetService } from '@/services/budget.service';
import { formatDate, getCurrencyInfo } from '@/utils/helpers';
import InfoModal from '@/components/molecules/InfoModal';
import deleteBudgetIcon from '@/assets/icons/delete-budget.svg';
import toast from 'react-hot-toast';
import { bankAccountService } from '@/services/bank-accounts.service';

const DetailPage = () => {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const { isMobileView } = useDeviceType();
  const { budgetId } = useParams();
  // const budgetId = id || '';
  const navigate = useNavigate();
  const [budget, setBudget] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [graphViewMode, setGraphViewMode] = useState('percentage');
  const [bankAccounts, setBankAccounts] = useState<any>([]);

  const fetchBudgetDetails = async () => {
    if (!budgetId) {
      setError('Budget ID not found');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      console.log('Fetching budget with ID:', budgetId);
      const { data } = await budgetService.getBudget(budgetId);

      setBudget(data);
    } catch (err) {
      console.error('Error fetching budget:', err);
      setError('Failed to load budget details');
    } finally {
      setLoading(false);
    }
  };

  // Update your fetchBankAccounts function to transform the data
  const fetchBankAccounts = async () => {
    if (budgetId) {
      try {
        const { data: bankAccountsData } = await bankAccountService.getBankAccounts(budgetId);

        // Transform the data into the expected format
        const formattedBankAccounts = bankAccountsData.map((account) => ({
          id: account._id,
          title: `${account.accountName}`,
          icon: vaultIcon,
          data: [
            {
              subtitle: 'Remaining balance',
              value: String(account.initialAmount || 0)
            }
          ]
        }));

        setBankAccounts(formattedBankAccounts);
      } catch (error) {
        console.error('Error fetching bank accounts:', error);
        setBankAccounts([]);
      }
    }
  };

  useEffect(() => {
    fetchBudgetDetails();
    fetchBankAccounts();
  }, [budgetId]);

  const handleDeleteClick = () => {
    setDeleteConfirmModal(true);
  };

  const handleDeleteBudget = async () => {
    console.log('budgetId:', budgetId);
    // if (!budgetId || !window.confirm('Are you sure you want to delete this budget?')) return;
    try {
      await budgetService.deleteBudget(budgetId!);

      toast.success('Budget deleted successfully');
      navigate('/budgets');
    } catch (err) {
      console.error('Error deleting budget:', err);
      alert('Failed to delete budget');
    }
  };

  const handleAddBankClick = () => {
    if (budgetId) {
      navigate(`/budgets/${budgetId}/bank-accounts/add`);
    }
  };

  // Calculate chart data based on budget values
  const calculateChartData = () => {
    if (!budget) {
      return {
        bmevChartData: [{ key: 'remaining', value: 100, fill: '#32ADE6' }],
        btrtChartData: [{ key: 'target', value: 100, fill: '#32ADE6' }]
      };
    }

    // Calculate percentages for BMEV (Budget Maximum Expense Value)
    let bmevData = [];
    if (budget.bmev !== undefined) {
      const bmevTotal = budget.bmev || 0;
      const bmevUsed = budget.bmev_used || 0;
      const bmevRemaining = Math.max(0, bmevTotal - bmevUsed);

      // Only add remaining if there's a total budget value
      if (bmevTotal > 0) {
        const bmevRemainingPercent = Math.round((bmevRemaining / bmevTotal) * 100);
        bmevData.push({ key: 'remaining', value: bmevRemainingPercent, fill: '#32ADE6' });

        const bmevUsedPercent = Math.round((bmevUsed / bmevTotal) * 100);
        bmevData.push({ key: 'spent', value: bmevUsedPercent, fill: '#FF2D55' });
      } else {
        // Default value if bmev is 0
        bmevData.push({ key: 'remaining', value: 100, fill: '#32ADE6' });
      }
    } else {
      // Default value if bmev is not defined
      bmevData.push({ key: 'remaining', value: 100, fill: '#32ADE6' });
    }

    console.log('bmevData:', bmevData);

    // Calculate percentages for BTRT (Budget Total Revenue Target)
    let btrtData = [];
    if (budget.btrt !== undefined) {
      const btrtTotal = budget.btrt || 0;
      // Using btrt_achieved as the API response shows this field, not btrt_used
      const btrtAchieved = budget.btrt_achieved || 0;

      // Only calculate percentages if there's a target
      if (btrtTotal > 0) {
        const btrtAchievedPercent = Math.round((btrtAchieved / btrtTotal) * 100);

        // Add target (always 100%)
        btrtData.push({ key: 'target', value: 100 - btrtAchievedPercent, fill: '#32ADE6' });

        btrtData.push({ key: 'achieved', value: btrtAchievedPercent, fill: '#34C759' });
      } else {
        // Default value if btrt is 0
        btrtData.push({ key: 'target', value: 100, fill: '#32ADE6' });
      }
    } else {
      // Default value if btrt is not defined
      btrtData.push({ key: 'target', value: 100, fill: '#32ADE6' });
    }

    return {
      bmevChartData: bmevData,
      btrtChartData: btrtData
    };
  };

  const { bmevChartData, btrtChartData } = calculateChartData();

  const bmevChartConfig = {
    remaining: {
      label: 'Remaining Budget',
      color: '#32ADE6'
    },
    spent: {
      label: 'Budget Spent',
      color: '#FF2D55'
    }
  } satisfies ChartConfig;

  const btrtChartConfig = {
    target: {
      label: 'Revenue Target',
      color: '#32ADE6'
    },
    achieved: {
      label: 'Achieved Revenue',
      color: '#34C759'
    }
  } satisfies ChartConfig;

  // Bank accounts data

  // Default accounting centers
  const accountingCenters: ListItem[] = [];

  // Expense and revenues
  const expenseAndRevenues: ListItem[] = [];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>Loading budget details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <>
      <MobileAppBar title="Budget Overview" onRightIconClick={handleDeleteClick} rightIcon={binIcon} />
      <div className="p-4">
        {/* BUDGET INFORMATION - MOBILE */}
        <div className="flex items-center justify-between gap-5 mb-6 md:hidden">
          <div className="flex items-center gap-3">
            <img src={budgetIcon} alt="budget-icon" width={40} height={40} />
            <div>
              <h4 className="font-semibold text-base">{budget?.name || 'BUDGET NAME'}</h4>
              <p className="text-secondary text-sm font-medium">{budget?.type || 'Company'}</p>
            </div>
          </div>

          <button type="button" onClick={() => navigate(`/budgets/edit/${budgetId}`)}>
            <img src={editIcon} alt="edit-icon" width={24} height={24} />
          </button>
        </div>

        <div className="md:flex md:gap-7">
          {/* BUDGET INFORMATION - WEB */}
          <BudgetInfoCard
            name={budget?.name}
            type={budget?.type}
            description={budget?.description}
            currency={budget?.currency}
            startDate={formatDate(budget?.start_date)}
            endDate={formatDate(budget?.end_date)}
            isAutoRenewal={budget?.is_auto_renewal}
            onEdit={() => navigate(`/budgets/edit/${budgetId}`)}
            onDelete={handleDeleteClick}
          />

          {/* GRAPH OVERVIEW */}
          <div className="md:w-[40%] md:bg-white md:p-[0.85rem] md:rounded-[16px]">
            <header className="w-full flex items-center justify-between mb-3 md:mb-4">
              <h3 className="text-base font-bold text-primary md:text-foreground">Graph Overview</h3>
              <Tabs defaultValue="percentage" value={graphViewMode} onValueChange={setGraphViewMode}>
                <TabsList className="bg-lightPurple-200 h-auto py-[0.2rem]">
                  <TabsTrigger value="percentage" className="group md:py-[0.3rem]">
                    <img
                      src={percentageIcon}
                      alt="percentage-icon"
                      width={13}
                      height={17}
                      className="group-data-[state=active]:brightness-[100]"
                    />
                  </TabsTrigger>
                  <TabsTrigger value="euro" className="group md:py-[0.3rem]">
                    <img
                      src={euroIcon}
                      alt="euro-icon"
                      width={12}
                      height={12}
                      className="w-[12px] h-[12px] group-data-[state=active]:brightness-[100]"
                    />
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </header>
            <div className="flex items-start justify-between gap-3">
              <PieGraph
                title="Remaining BMEV"
                titleValue={(() => {
                  if (graphViewMode === 'percentage') {
                    return bmevChartData.length > 0 ? `${bmevChartData[0].value}%` : '0%';
                  } else {
                    // Euro mode - calculate actual value
                    const bmevTotal = budget?.bmev || 0;
                    const bmevUsed = budget?.bmev_used || 0;
                    const bmevRemaining = Math.max(0, bmevTotal - bmevUsed);
                    return `€${bmevRemaining.toLocaleString()}`;
                  }
                })()}
                chartConfig={bmevChartConfig}
                chartData={bmevChartData}
                className="bg-white rounded-[16px] p-[0.85rem] flex-1 md:border border-lightGray md:py-10 md:flex md:items-center md:justify-center md:flex-col"
              />

              <PieGraph
                title="Achieved BTRT"
                titleValue={(() => {
                  if (graphViewMode === 'percentage') {
                    const achievedItem = btrtChartData.find((item) => item.key === 'achieved');
                    return achievedItem ? `${achievedItem.value}%` : '0%';
                  } else {
                    // Euro mode - display the actual achieved value
                    const btrtAchieved = budget?.btrt_achieved || 0;
                    return `€${btrtAchieved.toLocaleString()}`;
                  }
                })()}
                chartConfig={btrtChartConfig}
                chartData={btrtChartData}
                variant="primary"
                className="bg-white rounded-[16px] p-[0.85rem] flex-1 md:border border-lightGray md:py-10 md:flex md:items-center md:justify-center md:flex-col"
              />
            </div>
          </div>
        </div>

        {/* GENERAL INFORMATION - MOBILE */}
        {isMobileView && (
          <BudgetGeneralInfoAccordion
            description={budget?.description}
            bmevThreshold={budget?.bmev_alert_threshold}
            btrtThreshold={budget?.btrt_alert_threshold}
            currency={budget?.currency}
            autoRenewal={budget?.is_auto_renewal}
            startDate={formatDate(budget?.start_date)}
            endDate={formatDate(budget?.end_date)}
          />
        )}

        <div className="md:flex md:items-stretch md:justify-between md:gap-6 md:min-h-[300px]">
          {/* BANK ACCOUNTS */}
          <OverviewList
            title="Bank Accounts"
            items={bankAccounts}
            icon={vaultIcon}
            addButtonIcon={plusIcon}
            addButtonTitle="ADD BANK ACCOUNTS"
            valuePrefix={budget?.currency === 'EUR' ? '€' : '$'}
            valueSuffix="k"
            onAddClick={handleAddBankClick}
            className="mt-8 bg-lightPurple md:w-[33%] rounded-[16px] md:flex-2 md:bg-white md:border md:border-lightGray"
          />

          {/* ACCOUNTING CENTERS */}
          <OverviewList
            title="Accounting Centers"
            items={accountingCenters}
            icon={buildingIcon}
            addButtonIcon={plusIcon}
            addButtonTitle="ADD ACCOUNTING CENTERS"
            valuePrefix={budget?.currency === 'EUR' ? '€' : '$'}
            valueSuffix="k"
            onAddClick={() => {}}
            className="mt-8 bg-lightPurple md:w-[33%] rounded-[16px] md:flex-1 md:bg-white md:border md:border-lightGray"
          />

          {/* EXPENSE AND REVENUE */}
          <OverviewList
            title="Expense & Revenue"
            items={expenseAndRevenues}
            icon={vaultIcon}
            addButtonIcon={plusIcon}
            addButtonTitle="ADD EXPENSE & REVENUE"
            valuePrefix={budget?.currency === 'EUR' ? '€' : '$'}
            valueSuffix="k"
            onAddClick={() => {}}
            className="mt-8 bg-lightPurple md:w-[33%] rounded-[16px] md:flex-1 md:bg-white md:border md:border-lightGray"
          />
        </div>

        <div className="md:flex md:items-stretch md:justify-between md:gap-6">
          {/* INVOICE */}
          <section className="mt-7 md:flex-1 md:bg-white md:p-4 md:rounded-[16px] md:border md:border-lightGray md:flex md:flex-col">
            <p className="text-base font-bold">Invoice</p>
            <div className="bg-lightPurple rounded-[16px] p-6 flex items-center justify-center flex-col gap-3 mt-4 md:bg-white md:flex-1">
              <div className="flex flex-col items-center justify-center md:flex-row md:gap-4">
                <img src={noInvoiceIcon} alt="no invoice icon" className="md:w-[40px] md:h-[40px]" />
                <span className="mt-[3px] text-xs font-medium text-center leading-[16.5px] md:text-left">
                  <span className="md:text-base md:font-medium md:block mb-[-3px] md:text-foreground">
                    There are no Invoice to show.
                  </span>{' '}
                  <span className="md:text-secondary md:text-base md:font-medium">
                    {' '}
                    Please create a new invoice to allocate budget!
                  </span>
                </span>
              </div>
              <Button variant="primary-reverse" className="rounded-full font-semibold px-5 text-base">
                Create an Invoice
              </Button>
            </div>
          </section>

          {/* COMMITMENTS */}
          <div className="mt-7 md:bg-white md:p-4 md:rounded-[16px] md:border md:border-lightGray">
            <p className="text-base font-bold mb-4">Commitments</p>

            <Calendar
              mode="single"
              selected={date}
              onSelect={setDate}
              className="rounded-[16px] border border-lightGray py-6"
            />
          </div>
        </div>

        {/* Delete Confirm Modal */}
        {deleteConfirmModal && (
          <InfoModal
            isOpen={deleteConfirmModal}
            variant="confirm"
            title="Are you sure want to Delete this Budget?"
            message={<p>Deleted budget will removed permanently and unable to restore</p>}
            buttonText="Delete"
            onButtonClick={handleDeleteBudget}
            onClose={() => setDeleteConfirmModal(false)}
            icon={deleteBudgetIcon}
          />
        )}
      </div>
    </>
  );
};

export default DetailPage;
